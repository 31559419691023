.footer {
  padding-top: 2%;
  background-color: #0076e8;
  color: #ffffff;
}

.foocontenedor {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas:
    "logo"
    "section"
    "redes";
}

.foopersona {
  grid-area: logo;
  text-align: center;
  align-self: center;
}

.foosecciones {
  grid-area: section;
}
.foosecciones h4 {
  font-size: 1rem;
  margin-left: 2%;
}
.foosecciones ul {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding-left: 0px;
  height: 60px;
  align-content: space-around;
  flex-wrap: wrap;
}
.foosecciones ul li {
  list-style: none;
}

.foosecciones ul li a {
  text-decoration: none;
  color: inherit;
}

.foosocialMedia {
  grid-area: redes;
}

.foosocialMedia h4 {
  font-size: 1rem;
  margin-left: 2%;
}

.fooRedContainer {
  display: inline-block;
  margin: 3%;
  padding: 5px;
}

.fooRedContainer img {
  width: 25px;
}
@media screen and (min-width: 650px) {
  .foocontenedor {
    display: grid;
    grid-template-columns: 2fr 2fr 2fr;
    grid-template-rows: 1fr;
    grid-template-areas: "logo section redes";
  }
  .foosecciones ul {
    height: 90px;
  }
}
