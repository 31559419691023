@font-face {
  font-family: ubuntu-Medium;
  src: url(../tipografia/Ubuntu-Medium.ttf);
}

@font-face {
  font-family: ubuntu-Regular;
  src: url(../tipografia/Ubuntu-Regular.ttf);
}

.aptitudes {
  text-align: center;
}
.aptdivision {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: space-between;
}

.aptcontainer {
  border: 3px solid #f1f1f1;
  border-top: 0px;
  display: inline-block;
  width: 250px;
  padding: 2%;
  box-shadow: 8px 6px 2px #f1f1f1;
  border-radius: 5%;
}

.aptcontainer img {
  height: 75px;
}
h4 {
  font-size: 1.3rem;
  font-family: ubuntu-Medium;
}

.aptadquiridas {
  font-family: ubuntu-Regular;
  text-align: left;
}

.cerrado {
  display: none;
}

.show {
  display: block;
}

.aptadquiridas li {
  list-style: none;
}

@media screen and (min-width: 480px) {
  .aptcontainer {
    width: 300px;
  }
  .more {
    display: block;
  }

  .more:hover {
    text-decoration: underline;
    color: aqua;
    cursor: pointer;
  }
}
@media screen and (min-width: 650px) {
  .aptcontainer {
    height: 350px;
  }

  .aptcontainer img {
    height: 100px;
  }

  h4 {
    font-size: 1.3rem;
    font-family: ubuntu-Medium;
  }

  .more {
    display: none;
  }
  .aptadquiridas {
    display: block;
  }
}
@media screen and (min-width: 1024px) {
  .aptcontainer {
    width: 25%;
  }
}
